<template>
  <div style=" margin-bottom: 8px" class="dddcard">
    <el-card shadow="never">
      <Breadcrumb Otitle="操作指南" />

      <el-collapse accordion>
        <el-collapse-item
          v-for="(item, i) in newDatalist"
          :title="item.title"
          :name="i"
          :key="i"
        >
          <template slot="title">
            <div id="title" :title="item.title">
              <i class="header-icon el-icon-info"></i> {{ item.title }}
            </div>
          </template>
          <div v-html="item.clobDO.content" id="content"></div>
        </el-collapse-item>
      </el-collapse>

      <div class="hr-10"></div>
      <el-pagination
        background
        @current-change="collChange"
        layout="prev, pager, next"
        :total="total"
        style="margin: 16px 0 0 0"
      ></el-pagination>
      <div class="hr-10"></div>
    </el-card>
  </div>
</template>

<script>
import Breadcrumb from "@/components/WebPanel/Breadcrumb.vue";

export default {
  data() {
    return {
      page: 1,
      pages: 0,
      pageSize: 5,
      total: 1,
      activeNames: ["1"],
      newData: {
        title: "",
        content: "",
      },
      newDatalist: [],
      tableData: {
        columnId: "",
      },
      form: {
        columnId: 0,
        title: "",
        type: "",
        status: 0,
        content: "",
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$axios
        .get("/column/page")
        .then((response) => {
          for (let i = 0; i < response.data.data.records.length; i++) {
            if (response.data.data.records[i].name === "操作指南") {
              this.tableData.columnId = response.data.data.records[i].id;
              this.getIdData(this.tableData.columnId, 1);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getIdData(id, page) {
      let self = this;
      this.$axios
        .get("/article/column", {
          params: {
            columnId: id,
            current: page,
            size: 8,
          },
        })
        .then((response) => {
          self.total = response.data.data.total;
          response.data.data.records.forEach(function(item) {
            self.getArticle(item.id);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getArticle(id) {
      let self = this;
      this.$axios.get("/article?id=" + id).then((response) => {
        self.newDatalist.push(response.data.data);
      });
    },
    //分页
    collChange(val) {
      this.newDatalist = [];
      this.getIdData(this.tableData.columnId, val);
    },
  },

  components: {
    Breadcrumb,
  },
};
</script>

<style scoped>
.dddcard {
  margin: auto;
  max-width: 1200px;
  min-height: 400px;
}

#title {
  color: #477198;
  margin: 4px 8px;
  font-size: 16px;
  border-bottom: 1px #ebeef5 solid;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 100%;
  text-align: left;
}

#content {
  margin: 8px;
  text-align: left;
  text-indent: 2em;
  font-size: 15px;
  overflow: auto;
  word-break: break-word;
  max-height: 320px;
}

#content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #c1c1c1;
}
@import "../../styles/common_responsive.less";
</style>
